<template>
  <el-card shadow="never" class="m-1 print">
    <template #header>
      <el-row type="flex" justify="center" class="mt--5">
        <el-image
          style="width: 350px; height: 100px"
          :src="'/images/logo.png'"
          :fit="'contain'"
        ></el-image>
      </el-row>
      <el-row type="flex" justify="center">
        <small class="small-font"
          >Rua Cel Alcebiades de Miranda, Nº 639. Bairro Boa Vista, Ponta Grossa
          - PR. CEP 84.073-000</small
        >
      </el-row>
      <el-row :gutter="4" type="flex" justify="center">
        <i class="el-icon-message"></i>
        <small class="small-font">donauagronegocios@gmail.com</small>
        <i class="el-icon-phone" style="margin-left: 8px"></i>
        <small class="small-font"> (042) 3227-1744</small>
      </el-row>
      <el-row :gutter="4" type="flex" justify="center"> </el-row>
      <el-row type="flex" justify="space-between" align="center">
        <el-col
          ><el-row type="flex" justify="center"
            ><h4 class="m-0">Relatório de Animais</h4></el-row
          ></el-col
        >
      </el-row>
      <el-row type="flex" justify="space-between" align="center">
        <el-col
          ><el-row type="flex" justify="center"
            ><h5 class="m-0">Produtor(es):</h5>
            <h5 class="m-0">{{ getProviders() }}</h5>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Animals"
      style="width: 100%; z-index: 0"
    >
      <el-table-column prop="code" label="Nº"> </el-table-column>
      <el-table-column
        label="data"
        :formatter="(r) => formatDate(r.created_at)"
      >
      </el-table-column>

      <el-table-column label="produtor" prop="provider.name"> </el-table-column>
      <el-table-column
        label="carcaças"
        prop="carcasses.length"
      ></el-table-column>
      <el-table-column
        label="preço"
        prop="price"
        :formatter="(r) => currencyFormatter.format(Number(r.price) || 0)"
      ></el-table-column>
      <el-table-column label="peso aferido">
        <template #default="p">
          {{
            `${numberFormatter.format(
              p?.row?.carcasses?.reduce(
                (t, e) =>
                  (t += (Number(e?.weight) || 0) + (Number(e?.weight_) || 0)),
                0
              ) || 0
            )}`
          }}
          kg.
        </template>
      </el-table-column>
      <el-table-column label="peso declarado">
        <template #default="p">
          {{
            `${numberFormatter.format(
              (Number(p.row.weight) || 0) + (Number(p.row.weight_) || 0)
            )}`
          }}
          kg.
        </template>
      </el-table-column>
      <el-table-column label="preço total">
        <template #default="p">
          {{
            `${currencyFormatter.format(
              ((Number(p.row.weight_) || 0) + (Number(p.row.weight) || 0)) *
                (Number(p.row.price) || 0) -
                (Number(p.row?.weight || 0) + Number(p.row?.weight_ || 0)) *
                  Number(p.row?.price || 0) *
                  (Number(p?.row?.provider?.deduction || 0) / 100) || 0
            )}`
          }}
        </template>
      </el-table-column>
    </el-table>
    <el-row>
      <el-col :md="3" class="md-3-print">
        <p class="summary summary-black">Total:</p>
      </el-col>
      <el-col :md="3" class="md-3-print"> </el-col>
      <el-col :md="3" class="md-3-print"> </el-col>

      <el-col :md="3" class="md-3-print">
        <p class="summary summary-black">
          {{ sumCarcasses() }}
        </p>
      </el-col>
      <el-col :md="3" class="md-3-print">
        <p class="summary summary-black"></p>
      </el-col>
      <el-col :md="3" class="md-3-print">
        <p class="summary summary-black">
          {{ numberFormatter.format(sumMeasuredWeights() || 0) }}
          kg.
        </p>
      </el-col>
      <el-col :md="3" class="md-3-print">
        <p class="summary summary-black">
          {{ numberFormatter.format(sumTotalWeights() || 0) }}
          kg.
        </p>
      </el-col>
      <el-col :md="3" class="md-3-print">
        <p class="summary summary-black">
          {{ currencyFormatter.format(sumTotalPrice()) }}
        </p>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
//import Moment from "moment";
//import { ElNotification } from "element-plus";
export default {
  name: "PackingListReportPage",
  data: () => ({
    reports: null,
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      minimumFractionDigits: 2,
      style: "decimal",
    }),
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    }),
  }),
  computed: {
    Data() {
      return JSON.parse(this.$route?.query?.data || "[]");
    },
    Animals() {
      return this.Data || [];
    },
  },
  methods: {
    sumCarcasses() {
      return this.Animals?.map((a) => a?.carcasses?.length)?.reduce(
        (t, l) => (t += l),
        0
      );
    },
    sumTotalPrice() {
      return this.Animals?.map(
        (a) => (Number(a.price) || 0) * this.sumWeight(a)
      ).reduce((t, p) => (t += p), 0);
    },
    sumWeight(animal) {
      return (Number(animal.weight) || 0) + (Number(animal.weight_) || 0);
    },
    sumTotalWeights() {
      return this.Animals?.map(
        (a) => (Number(a?.weight) || 0) + (Number(a?.weight_) || 0)
      ).reduce((t, w) => (t += w), 0);
    },
    sumMeasuredWeights() {
      return this.Animals.map((a) => a?.carcasses)
        ?.flat()
        ?.map((c) => Number(c?.weight) || 0)
        .reduce((t, w) => (t += w), 0);
    },
    formatCurrency(c) {
      return c ? this.currencyFormatter.format(c) : "R$ 0,00";
    },
    getProviders() {
      if (this.Data?.length) {
        const providers = this.Data.map((d) => d.provider);
        const names = providers?.reduce((t, f) => {
          t[f?.name] = true;
          return t;
        }, {});

        return Object.keys(names)
          ?.filter((e) => e != "undefined")
          .join(", ");
      }
      return "";
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return new Date(c).toLocaleDateString();
      else return "desconhecido";
    },
  },
};
</script>
<style>
body {
  -webkit-print-color-adjust: exact;
}
.el-card {
  width: 100%;
}
.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 4px !important;
}
.text-red {
  color: red;
}
.m-0 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.m-1 {
  margin: 8px !important;
  max-width: 96%;
}
.text-center {
  text-align: center !important;
}
@media print {
  .el-table {
    width: 100% !important;
  }
  .el-table__header-wrapper {
    width: 100% !important;
  }
  .el-table__row {
    page-break-inside: avoid;
  }
  .el-table__body-wrapper {
    width: 100% !important;
  }
  div.md-12-print {
    max-width: 49% !important;
  }
  div.md-8-print {
    max-width: 33% !important;
  }
  div.md-3-print {
    max-width: 12.5% !important;
  }
  div:last-child {
    page-break-after: auto !important;
  }
  body,
  html {
    height: 95% !important;
  }
}
.cell {
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  width: 100%;
  justify-content: center;
}
.is-negative {
  color: red !important;
}
.is-positive {
  color: blue !important;
}
tr.problem {
  background-color: #fbe9e7;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.summary {
  color: #333;
  font-weight: 700 !important;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", SimSun, sans-serif;
  font-size: 12px;
  text-align: center;
}
.summary-black {
  color: #909399 !important;
}
</style>